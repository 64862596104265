import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

// Css Files
import "./styles/pages/_landing.css";
import "./styles/pages/_mint.css";
import "./styles/pages/_announcements.css";

ReactDOM.render(<App />, document.getElementById("root"));
