import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// Pages
import Landing from "./pages/Landing";
import Mint from "./pages/Mint";
import Announcements from "./pages/Announcements";
import Admin from "./pages/Admin";

const App = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <Landing />
          </Route>

          <Route path="/mint" exact>
            <Mint />
          </Route>

          <Route path="/announcements" exact>
            <Announcements />
          </Route>

          <Route path="/admin" exact>
            <Admin />
          </Route>
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
