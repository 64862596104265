import React, { useEffect } from "react";

const Announcements = () => {
  useEffect(() => {
    window.location.replace(
      "https://musicpromotiontoday.com/altlanteans/admin/"
    );
  }, []);

  return (
    <React.Fragment>
      <h1></h1>
    </React.Fragment>
  );
};

export default Announcements;
