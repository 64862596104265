import React from "react";

const AccordionHeader = ({ activeFaq, setActiveFaq, children, index }) => {
	return (
		<div
			className="question"
			onClick={() => {
				if (activeFaq === index) {
					setActiveFaq();
				} else {
					setActiveFaq(index);
				}
			}}
		>
			<p>{children}</p>
			<svg
				width="23"
				height="14"
				viewBox="0 0 23 14"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M11.1184 9.19045L19.3684 0.940454C19.5222 0.781271 19.7061 0.6543 19.9094 0.566952C20.1127 0.479604 20.3314 0.433627 20.5527 0.431704C20.774 0.429781 20.9935 0.47195 21.1983 0.555752C21.4032 0.639553 21.5892 0.763308 21.7457 0.919796C21.9022 1.07628 22.026 1.26237 22.1098 1.4672C22.1936 1.67202 22.2358 1.89149 22.2338 2.11279C22.2319 2.33409 22.1859 2.55279 22.0986 2.75613C22.0112 2.95947 21.8843 3.14338 21.7251 3.29712L12.2967 12.7255C11.9842 13.0379 11.5604 13.2134 11.1184 13.2134C10.6765 13.2134 10.2526 13.0379 9.94008 12.7255L0.511743 3.29712C0.35256 3.14338 0.225589 2.95947 0.138241 2.75613C0.0508927 2.55279 0.00491564 2.33409 0.00299262 2.11279C0.00106959 1.89149 0.0432391 1.67202 0.127041 1.4672C0.210842 1.26237 0.334597 1.07628 0.491085 0.919796C0.647573 0.763308 0.833659 0.639553 1.03849 0.555752C1.24331 0.47195 1.46278 0.429781 1.68408 0.431704C1.90538 0.433627 2.12408 0.479604 2.32742 0.566952C2.53076 0.6543 2.71467 0.781271 2.86841 0.940454L11.1184 9.19045Z"
					fill="white"
				/>
			</svg>
		</div>
	);
};

export default AccordionHeader;
