import React, {
	Children,
	cloneElement,
	useEffect,
	useRef,
	useState,
} from "react";

const Accordion = ({ children, activeFaq, index }) => {
	const [headerHeight, setHeaderHeight] = useState(0);
	const [parentHeight, setParentHeight] = useState(0);

	const ref = useRef(null);

	useEffect(() => {
		let parent = ref.current;

		if (parent) {
			setHeaderHeight(parent.children[0].scrollHeight);
			setParentHeight(parent.scrollHeight);
		}
	}, [ref.current]);

	const modifiedChildren = Children.map(children, (child, ind) => {
		if (ind === 0) return cloneElement(child);
		else return child;
	});

	return (
		<div
			ref={ref}
			style={{
				height: activeFaq === index ? parentHeight + 50 : headerHeight + 15,
			}}
			className={
				activeFaq === index ? "question-answer active" : "question-answer"
			}
		>
			{modifiedChildren}
		</div>
	);
};

export default Accordion;
